const globalConfig = window.globalConfig || {};

/*
  process.env.* gets set by webpack at build-time which is fine
  for dev environments, but will not work in Docker / Kubernetes.
  In Kubernetes, we use an init container to write the correct values
  to a script on window.globalConfig which allows the environment variables
  to override the webpack values at run time.
*/

const config = {
  REACT_APP_BASE_URL: globalConfig.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
  REACT_APP_LOOK_API_URL: globalConfig.REACT_APP_LOOK_API_URL || process.env.REACT_APP_LOOK_API_URL,
  REACT_APP_SCHEMA_BUILDER_URL: globalConfig.REACT_APP_SCHEMA_BUILDER_URL || process.env.REACT_APP_SCHEMA_BUILDER_URL,
  REACT_APP_AUTH0_DOMAIN: globalConfig.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: globalConfig.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_API_AUDIENCE: globalConfig.REACT_APP_AUTH0_API_AUDIENCE || process.env.REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_PAYPAL_CHECKOUT_URL:
    globalConfig.REACT_APP_PAYPAL_CHECKOUT_URL || process.env.REACT_APP_PAYPAL_CHECKOUT_URL,
  REACT_APP_AUTHORIZENET_CHECKOUT_URL:
    globalConfig.REACT_APP_AUTHORIZENET_CHECKOUT_URL || process.env.REACT_APP_AUTHORIZENET_CHECKOUT_URL,
  REACT_APP_EBAY_AFFILIATE_ID: globalConfig.REACT_APP_EBAY_AFFILIATE_ID || process.env.REACT_APP_EBAY_AFFILIATE_ID,
};

module.exports = config;
